import {Component, Injector} from '@angular/core';
import {OrderState, Store} from './state/store';
import {Order} from './state/model';
import {EntityListComponent} from '../../components/entity-list/component';
import {FilterService} from './filter.service';
import {OrderQuery} from './state/query';
import {OrderService} from './state/service';
import {FieldConfig} from "../../components/form/field.interface";

@Component({
  templateUrl: 'list.html',
  styleUrls: ['../../components/entity-list/component.scss']
})
export class OrderListComponent extends EntityListComponent<Order, OrderState, Store> {

  translationDomain = 'order';

  constructor(
    public injector: Injector,
    protected service: OrderService,
    query: OrderQuery,
    private OrderFilterService: FilterService) {
    super(injector, service, query, OrderFilterService);
  }
  canEdit(): boolean {
    return this.hasRole('ROLE_ADMIN');
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.sort.sort({
      id: 'createdAt',
      start: 'desc',
      disableClear: false
    });
  }


  fieldConfig(name: string): FieldConfig | undefined {
    const type = FieldConfig.create(Object.assign({}, this.fields.find(item => item.name === name)) as FieldConfig);

    type.disabled = false;
    type.editable = true;
    type.creatable = null;
    type.entityConditionalOptions = null;
    switch (type.type) {
      case 'date':
        type.type = 'dateRange';
        break;
      case 'address':
      case 'partnerAddress':
        type.type = 'input';
        type.inputType = 'text';
        break;
      case 'autocomplete':
        type.type = 'autocomplete';
        type.entityConditionalOptions = this.fields.find(item => item.name === name)?.entityConditionalOptions;
        break;
      case 'phone':
        type.type = 'input';
        type.inputType = 'text';
        break;
    }
    return type;
  }
}
