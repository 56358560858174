import {NgModule, Pipe} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoutingModule} from './routing';
import {MaterialModule} from '../material/material.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {EntityListComponent} from './entity-list/component';
import {EntityDetailComponent} from './entity-detail/component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormComponent} from './form/dynamic-form';
import {DynamicFieldDirective} from './form/dynamic-field.directive';
import {ButtonComponent} from './form/form-elements/button';
import {CheckboxComponent} from './form/form-elements/checkbox';
import {DateComponent} from './form/form-elements/datepicker';
import {InputComponent} from './form/form-elements/input';
import {RadiobuttonComponent} from './form/form-elements/radio';
import {SelectComponent} from './form/form-elements/select';
import {TranslocoModule} from '@ngneat/transloco';
import {ConfirmDialogComponent} from './confirmation-dialog/component';
import {EntitySelectComponent} from './form/form-elements/entitySelect';
import {DynamicFormHostDirective} from './form/dynamic-form-host.directive';
import {SubEntityComponent} from './form/form-elements/subEntity';
import {DateRangeComponent} from './form/form-elements/daterange-picker';
import {WysiwygComponent} from './form/form-elements/wysiwyg';
import {AddressComponent} from './form/form-elements/address';
import {PhoneComponent} from './form/form-elements/phone';
import {EntityViewComponent} from './entity-view/component';
import {DateDayPipe} from './datePipe';
import {ChipsComponent} from './form/form-elements/chips';
import {AdditionalFiltersComponent} from './additional-filters/component';
import {DateTimePipe} from './dateTimePipe';
import {CustomComponent} from './form/form-elements/custom';
import {AutocompleteComponent} from './form/form-elements/autocomplete';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {NgxEditorModule} from "ngx-editor";
import {ImageComponent} from "./form/form-elements/image";
import {ColorComponent} from "./form/form-elements/color";
import {ColorPickerModule} from "ngx-color-picker";
import {WebshopClientModule} from "../webshop-client/module";

@Pipe({name: 'default', pure: true})
export class DefaultPipe {
  transform(value: any, defaultValue: any): any {
    return value || defaultValue;
  }
}

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    EntityListComponent,
    EntityDetailComponent,
    EntityViewComponent,
    DynamicFormComponent,
    DynamicFieldDirective,
    DynamicFormHostDirective,
    ButtonComponent,
    CheckboxComponent,
    DateComponent,
    InputComponent,
    RadiobuttonComponent,
    SelectComponent,
    EntitySelectComponent,
    SubEntityComponent,
    DateRangeComponent,
    WysiwygComponent,
    AddressComponent,
    PhoneComponent,
    ChipsComponent,
    DefaultPipe,
    DateDayPipe,
    DateTimePipe,
    AdditionalFiltersComponent,
    CustomComponent,
    AutocompleteComponent,
    ImageComponent,
    ColorComponent
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    DynamicFormComponent,
    DynamicFieldDirective,
    DynamicFormHostDirective,
    DefaultPipe,
    DateDayPipe,
    DateTimePipe,
    ImageComponent,
    DateRangeComponent,
    InputComponent,
    EntitySelectComponent,
    WysiwygComponent,
  ],
    imports: [
        CommonModule,
        MaterialModule,
        RoutingModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        ReactiveFormsModule,
        TranslocoModule,
        NgxEditorModule,
        MatAutocompleteModule,
        FormsModule,
        ColorPickerModule,
    ],
  providers: [
    DateTimePipe,
    DateDayPipe
  ]
})
export class Module {
}
