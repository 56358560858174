<mat-nav-list>
  <div mat-subheader>Felhasználók</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/users/user'] | localize">Webshop felhasználók</a>
  <div mat-subheader>Sajtó</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/press-download'] | localize">Letöltések</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/press-news'] | localize">Fontosabb megjelenések</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/press-video'] | localize">Videók</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/press-config'] | localize">Beállítások</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/press-user'] | localize">Felhasználók</a>
  <div mat-subheader>Termékek</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/category'] | localize">Kategóriák</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/products/product'] | localize">Termékek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/products/product-parameter'] | localize">Termékparaméter típusok</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/products/product-parameter-type'] | localize">Termékparaméterek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/products/product-parameter-format'] | localize">Formátumok</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/tag'] | localize">Tagek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/reseller'] | localize">Viszonteladók</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/coupon'] | localize">Kuponkódok</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/payment-mode'] | localize">Fizetési mód</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/transporter'] | localize">Szállítók</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/transporter-discount'] | localize">Szállítói kedvezmények</a>
  <div mat-subheader>Webshop</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/order'] | localize">Rendelések</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/order/export/manufacturable'] | localize">Gyártandók export</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/order/export/excel'] | localize">Excel export</a>
  <div mat-subheader>Tartalmak</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/page'] | localize">Aloldalak</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/news'] | localize">Hírek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/blog'] | localize">Blog</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/blog-category'] | localize">Blog kategóriák</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/products/gallery'] | localize">Galériák</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/popup'] | localize">Popupok</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/wallpaper'] | localize">Háttérképek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/expression'] | localize">Fordítások</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/content/newsletter'] | localize">Hírlevelek</a>
  <div mat-subheader>Beállítások</div>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/language'] | localize">Nyelvek</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/country'] | localize">Országok</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/config'] | localize">Beállítások</a>
  <a mat-list-item (click)="onSidenavClose()" [routerLink]="['/admin/webshop/store'] | localize">Üzletek</a>
</mat-nav-list>
