<mat-toolbar-row>
  {{ service.getEntityTitle() | async }} {{ 'form.editing' | transloco }}
</mat-toolbar-row>
<mat-toolbar-row>
  <button mat-button mat-raised-button [color]="tab === 'user' ? 'primary' : 'accent'" (click)="tab = 'user'">Megrendelő
    adatok
  </button>
  <button mat-button mat-raised-button [color]="tab === 'billing' ? 'primary' : 'accent'" (click)="tab = 'billing'">
    Számlázási cím
  </button>
  <button mat-button mat-raised-button [color]="tab === 'shipping' ? 'primary' : 'accent'" (click)="tab = 'shipping'">
    Szállítási cím
  </button>
  <button mat-button mat-raised-button [color]="tab === 'products' ? 'primary' : 'accent'" (click)="tab = 'products'">
    Termékek
  </button>
  <button *ngIf="(entity$ | async)?.shippingMode.handlerClass === 'App:Classes:FedExShipping:Shipping'"
          mat-button mat-raised-button [color]="tab === 'fedex' ? 'primary' : 'accent'" (click)="tab = 'fedex'">FedEx
  </button>
  <button mat-button mat-raised-button [color]="tab === 'status' ? 'primary' : 'accent'" (click)="tab = 'status'">
    Státusz
  </button>
  <button mat-button mat-raised-button [color]="tab === 'logs' ? 'primary' : 'accent'" (click)="tab = 'logs'">Logok
  </button>
</mat-toolbar-row>
<div style="
  display: flex;
  justify-content: center;" *ngIf="(entity$ | async) as order">
  <form class="dynamic-form">
    <mat-card>
      <mat-card-content>
        <div *ngIf="tab === 'user'">
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Megrendelés száma</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.orderId.toString()"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Keresztnév</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.user.firstName"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Vezetéknév</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.user.lastName"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.user.email"
                     [type]="'text'">
            </mat-form-field>
          </p>
        </div>
        <div *ngIf="tab === 'billing'">
          <ng-container *ngIf="order.billingAddress as address">
            <p *ngIf="!address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Keresztnév</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.firstName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p *ngIf="!address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Vezetéknév</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.lastName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p *ngIf="address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Cégnév</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.companyName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Ország</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="order.billingCountry.name"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Irányítószám</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.postalCode"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Város</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.city"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Utca, házszám</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.street"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Telefon</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="address.phone"
                       [type]="'text'">
              </mat-form-field>
            </p>
          </ng-container>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Követőkód</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.trackingCode"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Fizetési mód</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.paymentMode.toString()"
                     [type]="'text'">
            </mat-form-field>
          </p>
        </div>
        <div *ngIf="tab === 'shipping'" [formGroup]="shippingGroup">
          <ng-container *ngIf="order.shippingAddress as address">
            <p *ngIf="address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Cég neve</mat-label>
                <input matInput
                       [formControlName]="'company'"
                       [placeholder]="''"
                       [value]="address.companyName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p *ngIf="!address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Keresztnév</mat-label>
                <input matInput
                       [formControlName]="'firstName'"
                       [placeholder]="''"
                       [value]="address.firstName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p *ngIf="!address.isCompany">
              <mat-form-field appearance="fill">
                <mat-label>Vezetéknév</mat-label>
                <input matInput
                       [formControlName]="'lastName'"
                       [placeholder]="''"
                       [value]="address.lastName"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Ország</mat-label>
                <input matInput [disabled]="true"
                       [placeholder]="''"
                       [value]="order.shippingCountry.name"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Irányítószám</mat-label>
                <input matInput
                       [formControlName]="'postalCode'"
                       [placeholder]="''"
                       [value]="address.postalCode"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Város</mat-label>
                <input matInput
                       [formControlName]="'city'"
                       [placeholder]="''"
                       [value]="address.city"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Utca, házszám</mat-label>
                <input matInput
                       [formControlName]="'street'"
                       [placeholder]="''"
                       [value]="address.street"
                       [type]="'text'">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill">
                <mat-label>Telefon</mat-label>
                <input matInput
                       [formControlName]="'phone'"
                       [placeholder]="''"
                       [value]="address.phone"
                       [type]="'text'">
              </mat-form-field>
            </p>
          </ng-container>
          <p>
            <mat-form-field appearance="fill">
              <mat-label>Szállítási mód</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.shippingMode.toString()"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p *ngIf="order.shippingMode.handlerClass === 'App:Classes:PersonalShipping:Shipping'">
            <mat-form-field appearance="fill">
              <mat-label>Bolt</mat-label>
              <input matInput [disabled]="true"
                     [placeholder]="''"
                     [value]="order.store.toString()"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <button type="button" mat-raised-button color="primary" (click)="saveOrder($event)">
              Mentés
            </button>
          </p>
        </div>
        <div *ngIf="tab === 'fedEx'">

          <!--sub package type -->

        </div>
        <div *ngIf="tab === 'products'">

          <table mat-table [dataSource]="dataSource$ | async" class="mat-elevation-z1">
            <ng-container matColumnDef="product">
              <th mat-header-cell>Termék</th>
              <td mat-cell *matCellDef="let cartItem">
                {{ cartItem.product.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="productParameters">
              <th mat-header-cell>Termék paraméterek</th>
              <td mat-cell *matCellDef="let cartItem">
                <ul class="cartItem-detailsListing"
                    *ngIf="cartItem.productVariant.productParameterTypeCounts.length">
                  <ng-container
                    *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                    <li
                      *ngIf="cartItem.product.selectorProductParameter && cartItem.product.setCount && cartItem.product.selectorProductParameter.id !== productParameterTypeCount.productParameterType.productParameter.id">
                      <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                      – {{ productParameterTypeCount.productParameterType.name }}
                    </li>
                  </ng-container>
                  <ng-container
                    *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                    <li
                      *ngIf="cartItem.product.selectorProductParameter.id && !cartItem.product.setCount">
                      <span>{{ productParameterTypeCount.productParameterType.name }}</span>
                    </li>
                  </ng-container>

                  <ng-container
                    *ngFor="let productParameterTypeCount of cartItem.productVariant.productParameterTypeCounts">
                    <li *ngIf="!cartItem.product.selectorProductParameter.id">
                      <span>{{ productParameterTypeCount.productParameterType.productParameter.displayName }}</span>
                      – {{ productParameterTypeCount.productParameterType.name }}
                    </li>
                  </ng-container>

                  <li *ngIf="cartItem.caption">
                    <span>{{ 'webshop.Felirat' | transloco }} - </span> {{ cartItem.caption }}
                  </li>
                  <li *ngIf="cartItem.captionSpecial">
                    <span>{{ 'webshop.Felirat2' | transloco }} - </span> {{ cartItem.captionSpecial }}
                  </li>
                </ul>
              </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <th mat-header-cell>Darab</th>
              <td mat-cell *matCellDef="let element">{{ element.quantity }} db</td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell>Ár</th>
              <td mat-cell *matCellDef="let element">{{ element.price | currency: order.currency : 'symbol' : '0.0-2' : 'hu' }}</td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: ['product', 'productParameters', 'quantity', 'price'];"></tr>
          </table>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Összesen</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.sumPrice | currency: order.currency : 'symbol' : '0.0-2' : 'hu'"
                     [type]="'text'">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Kuponkód</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.coupon?.code"
                     [type]="'text'">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Kupon kedvezmény</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.couponDiscount | currency: order.currency : 'symbol' : '0.0-2' : 'hu'"
                     [type]="'text'">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Szállítás</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.shippingPrice | currency: order.currency : 'symbol' : '0.0-2' : 'hu'"
                     [type]="'text'">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Összes fizetendő</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.sumDiscountedPrice + order.shippingPrice | currency: order.currency : 'symbol' : '0.0-2' : 'hu'"
                     [type]="'text'">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field appearance="fill">
              <mat-label>Megjegyzés</mat-label>
              <input matInput [disabled]="true"
                     [value]="order.innerDescription"
                     [type]="'text'">
            </mat-form-field>
          </p>
        </div>
        <div *ngIf="tab === 'status'">
          <p>
            <mat-form-field [formGroup]="statusGroup">
              <mat-select [placeholder]="'Aktuális állapot'" [formControl]="statusControl">
                <mat-option *ngFor="let item of getPossibleOptions(order)"
                            [value]="item">{{ 'order.status-item.' + item | transloco}}</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field [formGroup]="statusGroup" appearance="fill">
              <mat-label>Megjegyzés</mat-label>
              <input matInput [formControl]="descriptionControl"
                     [placeholder]="''"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <mat-form-field *ngIf="statusControl.value === 5" [formGroup]="statusGroup" appearance="fill">
              <mat-label>Követőkód</mat-label>
              <input matInput [formControl]="trackingControl"
                     [placeholder]="''"
                     [type]="'text'">
            </mat-form-field>
          </p>
          <p>
            <button type="button" mat-raised-button color="primary" (click)="saveOrderStatus($event)">
              Mentés
            </button>
          </p>
        </div>
        <div *ngIf="tab === 'logs'">

          <h5>Megjegyzések</h5>
          <ul class="cartItem-detailsListing">
            <li *ngFor="let log of (logs$ | async) | orderBy: '-createdAt'">
              <span>{{ log.createdAt | date:'longDate' }}</span>
              <p [innerHTML]="log.content"></p>
            </li>
          </ul>
        </div>
        <div *ngIf="tab === 'fedex'">
          <app-fedex-order [order]="entity$ | async"></app-fedex-order>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
