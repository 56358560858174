import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FieldConfig} from '../field.interface';
import {Observable, of} from 'rxjs';
import {Entity} from '../../entity';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-autocomplete',
  styleUrls: ['common.scss', 'autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'autocomplete.html'
})
export class AutocompleteComponent implements OnInit, AfterViewInit {

  @ViewChild(MatAutocomplete) autoComplete: MatAutocomplete | undefined;

  field: FieldConfig;
  group: FormGroup;
  parentEntity$: Observable<Entity> = of({id: '', createdAt: new Date(), updatedAt: new Date(), getNameProperty: () => ''});
  _parentEntity$: Observable<Entity> = of({id: '', createdAt: new Date(), updatedAt: new Date(), getNameProperty: () => ''});
  customContent$ = new Observable<string>();
  filteredOptions$: Observable<Entity[]> | undefined;

  elements: Entity[] = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  inputControl: FormControl | undefined;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private eRef: ElementRef,
    public translationService: TranslocoService
  ) {
    this.group = new FormGroup({});
    this.field = new FieldConfig();

    this.inputControl = new FormControl('');
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.autoComplete?.isOpen || this.eRef.nativeElement.contains(event.target) || this.autoComplete?.options.some(option => option._getHostElement().contains(event.target))) {
    } else {
      if (!this.field.multiple) {
        this.group?.get(this.field.name)?.setValue(null);
        this.inputControl.reset('');
      }
    }
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value && !(this.group?.get(this.field.name)?.value as Entity[]).some(item => item.slug === value)) {
      this.elements = Object.assign([], this.group.get(this.field.name)?.value);
      //this.elements.push({slug: value});

      this.group.get(this.field.name)?.setValue(this.elements);
    }

    // Clear the input value
    event.input.value = '';
  }

  remove(element: Entity): void {
    const index = this.group?.get(this.field.name)?.value.indexOf(element);

    if (index >= 0) {
      this.group?.get(this.field.name)?.value.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.field.multiple) {
      this.group?.get(this.field.name)?.setValue(event.option.value);
    } else {
      const val = Object.assign([], this.group?.get(this.field.name)?.value);
      if (!val.find((item: { id: any; }) => item.id === event.option.value.id)) {
        val.push(event.option.value);
      }
      this.group?.get(this.field.name)?.setValue(val);
      this.inputControl.reset('');
    }
  }


  ngOnInit() {
    const callable = this.field.entityConditionalOptions as (form?: (FormGroup | undefined), parent?: FormGroup) => Observable<Entity[]>;
    const group = this.group.parent ?? this.group;
    if (this.field.multiple) {
      this.filteredOptions$ =
        this.inputControl?.valueChanges.pipe(
          distinctUntilChanged(),
          debounceTime(900),
          filter(item => item.length > 2),
          switchMap((value) => {
            return callable(this.inputControl as unknown as FormGroup, this.group).pipe(map(items => {
              return items.sort((a, b) => {
                if (a.toString().toLowerCase().startsWith(value.toLowerCase()) && !b.toString().toLowerCase().startsWith(value.toLowerCase())) {
                  return -1;
                }
                if (!a.toString().toLowerCase().startsWith(value.toLowerCase()) && b.toString().toLowerCase().startsWith(value.toLowerCase())) {
                  return 1;
                }
                return 0;
              } );
            }));
          })
        );
    } else {
      this.filteredOptions$ =
        this.inputControl?.valueChanges.pipe(
          distinctUntilChanged(),
          debounceTime(900),
          switchMap((value) => {
            if (!value) {
              this.group?.get(this.field.name)?.setValue(null);
            }
            if (value && value.length > 2) {
              return callable(this.inputControl as unknown as FormGroup, this.group).pipe(map(items => {
                return items.sort((a, b) => {
                  if (a.toString().toLowerCase().startsWith(value.toLowerCase()) && !b.toString().toLowerCase().startsWith(value.toLowerCase())) {
                    return -1;
                  }
                  if (!a.toString().toLowerCase().startsWith(value.toLowerCase()) && b.toString().toLowerCase().startsWith(value.toLowerCase())) {
                    return 1;
                  }
                  return 0;
                } );
              }));
            } else {
              return of([]);
            }
          })
        );

      if (this.group.get(this.field.name)?.value) {
        this.inputControl.setValue(this.group.get(this.field.name)?.value);
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {

      this._parentEntity$ = this.parentEntity$.pipe(
        tap(entity => {
          if (entity[this.field.name]) {
            this.inputControl.setValue(entity[this.field.name]);
          }
        })
      );
    });
  }
}
