<section class="l-siteContent" id="theTop" *ngIf="stores$ | async as stores">
  <section class="contact">
    <section *ngIf="googleApi.apiLoaded | async">
      <div class="row g-top-extrathick" data-equalizer data-equalize-on="medium">
        <div class="small-12 xlarge-6 column" *ngFor="let store of (stores | orderByWeight: 'title')">
          <h2>{{ store.title }}</h2>
          <div class="decorBorder decorBorder--block">
            <div>
              <google-map [width]="null" [height]="null" [ngClass]="{'contact-map-content': true, 'store-map': true }" [options]="options"
                          [center]="center(store)">
                <map-marker
                  [title]="store.title"
                  [label]="{text: store.title, fontSize: '16px', fontWeight: 'bold', color: '#444'}"
                  [position]="center(store)"></map-marker>
              </google-map>
            </div>
          </div>
          <div class="contact-address g-bottom-thin" data-equalizer-watch>
            <p><strong [innerHTML]="store.address"></strong><br>
            <p><strong [innerHTML]="store.phoneNumber"></strong><br>
              <span [innerHTML]="store.description"></span></p>
            <p><strong><span class="h-upperCase">{{ 'webshop.Nyitva' | transloco }}:</span></strong><br>
              <span [innerHTML]="store.openingTime"></span></p>
          </div>

          <app-gallery *ngIf="store.gallery.id" [gallery]="store.gallery"></app-gallery>

        </div>

      </div>
    </section>
    <section>
      <h1 class="l-sectionHeading">
        <div class="l-headFrizDecor"></div>
        {{ 'webshop.Bomo Art' | transloco }} – <span>{{ 'webshop.Viszonteladók' | transloco }}</span></h1>
      <div class="row">
        <div class="small-12 column">
          <div class="decorBorder decorBorder--block">
            <div>
              <div class="contact-map-contentWholesale">
                <iframe src="https://www.google.com/maps/d/embed?mid=z81WUehLKJLk.kUpMdltiX80g" width="1160"
                        height="650" frameborder="0" style="border:0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section class="g-top-extrathick">
    <header class="sectionHeader g-row-extrathick">
      <h2><img src="/assets/images/heading_divider_left.svg"
               alt="decor">{{ 'webshop.Kiemelt magyarországi viszonteladóink' | transloco }}<img
        src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
    </header>
    <ul class="list list--noTopPadding list--flexFlip">
      <li *ngFor="let reseller of (filterResellers((resellers$ | async), true, true) | orderByWeight: 'name')">

        <div class="anim flexCard">
          <div class="flexCard-img">
            <!--
image width:
small: (480)px
medium: (768)px
large: (1024 )px
xlarge: (1440 )px
xxlarge: (1920)px
-->
            <a class="flexCard-img-pic" [routerLink]="['reseller', reseller.slug] | localize">
              <app-image
                [small]="reseller.image.reseller_image_small"
                [medium]="reseller.image.reseller_image_medium"
                [large]="reseller.image.reseller_image_large"
                [xlarge]="reseller.image.reseller_image_xxlarge"></app-image>
            </a>
          </div>
          <div class="flexCard-content">
            <div style="width: 100%">
              <div class="flexCard-content-text">
                <h2>{{ reseller.title }}</h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p [innerHTML]="reseller.description"></p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <section class="g-top-extrathick">
    <header class="sectionHeader g-row-extrathick">
      <h2><img src="/assets/images/heading_divider_left.svg"
               alt="decor">{{ 'webshop.Magyarországi viszonteladóink' | transloco }}<img
        src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
    </header>
    <!-- images: 140x100px -->
    <div class="row small-up-2 medium-up-3 large-up-4 xlarge-up-5 align-center">
      <div class="column" *ngFor="let reseller of (filterResellers((resellers$ | async), true, false) | orderByWeight: 'name')">

        <a class="logoItem" [href]="reseller.url" target="_blank">
          <img [src]="reseller.logo.reseller_image_medium">
          <div>{{ reseller.title }}</div>
        </a>
      </div>
    </div>
  </section>

  <section class="g-top-extrathick">
    <header class="sectionHeader g-row-extrathick">
      <h2><img src="/assets/images/heading_divider_left.svg"
               alt="decor">{{ 'webshop.Kiemelt külföldi viszonteladóink' | transloco }}<img
        src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
    </header>
    <ul class="list list--noTopPadding list--flexFlip">
      <li *ngFor="let reseller of (filterResellers((resellers$ | async), false, true) | orderByWeight: 'name')">

        <div class="anim flexCard">
          <div class="flexCard-img">
            <!--
image width:
small: (480)px
medium: (768)px
large: (1024 )px
xlarge: (1440 )px
xxlarge: (1920)px
-->
            <a class="flexCard-img-pic" [routerLink]="['reseller', reseller.slug] | localize">
              <app-image
                [small]="reseller.image.reseller_image_small"
                [medium]="reseller.image.reseller_image_medium"
                [large]="reseller.image.reseller_image_xlarge"
                [xlarge]="reseller.image.reseller_image_xxlarge"></app-image>
            </a>
          </div>
          <div class="flexCard-content">
            <div>
              <div class="flexCard-content-text">
                <h2>{{ reseller.title }}</h2>
                <div class="l-divider l-divider--flexCard"></div>
                <p [innerHTML]="reseller.description"></p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <section class="g-top-extrathick">
    <header class="sectionHeader g-row-extrathick">
      <h2><img src="/assets/images/heading_divider_left.svg"
               alt="decor">{{ 'webshop.Külföldi viszonteladóink' | transloco }}
        <img src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
    </header>
    <!-- images: 140x100px -->
    <div class="row small-up-2 medium-up-3 large-up-4 xlarge-up-5 align-center">
      <div class="column" *ngFor="let reseller of (filterResellers((resellers$ | async), false, false) | orderByWeight: 'name')">

        <a class="logoItem" [href]="reseller.url" target="_blank">
          <img [src]="reseller.logo.reseller_image_medium">
          <div>{{ reseller.title }}</div>
        </a>
      </div>
    </div>
  </section>
  <section>
    <header class="sectionHeader g-row-extrathick">
      <h2><img src="/assets/images/heading_divider_left.svg"
               alt="decor">{{ 'webshop.Szeretnél viszonteladónk lenni? Írj nekünk!' | transloco }} <img
        src="/assets/images/heading_divider_right.svg" alt="decor"></h2>
    </header>
    <div class="row align-center">
      <div class="small-12 medium-10 large-6 xlarge-5 column">
        <form class="form form--contact form--inputTextTransformNone" data-abide novalidate [formGroup]="group">
          <div data-abide-error class="alert callout h-hidden">
            <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
          </div>
          <label>{{ 'webshop.Név' | transloco }}
            <input type="text" [placeholder]="'webshop.Név' | transloco" [formControl]="nameControl">
            <span class="form-error" *ngIf="nameControl.hasError('required') && nameControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <label>{{ 'webshop.E-mail' | transloco }}
            <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControl]="emailControl">
            <span class="form-error" *ngIf="emailControl.hasError('required') && emailControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
            <span class="form-error" *ngIf="emailControl.hasError('email') && emailControl.touched">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
          </label>
          <label>{{ 'webshop.Megjegyzés' | transloco }}
            <textarea [formControl]="descriptionControl"  rows="8" [placeholder]="'webshop.Megjegyzés' | transloco"></textarea>
            <span class="form-error" *ngIf="descriptionControl.hasError('required') && descriptionControl.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
          </label>
          <div class="g-top text-right">
            <img class="l-hand" src="/assets/images/bomo_hand_gray.svg" alt="tovább">
            <button class="btn btn--basic" (click)="sendMail()">{{ 'webshop.Küldés' | transloco }}</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</section>
