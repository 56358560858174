<div *ngIf="!field.multiple && ((_parentEntity$ | async) || true)">
  <mat-form-field *ngIf="field.name && group.get(field.name)" [formGroup]="group" appearance="fill">
    <mat-label>{{ field.label | async | default: '' }}</mat-label>
    <input type="text" [formControl]="inputControl" [attr.disabled]="field.disabled" matInput [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option"> {{ option.toString() }}
      </mat-option>
    </mat-autocomplete>
    <ng-container matPrefix>
      <mat-icon>search</mat-icon>
    </ng-container>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="group?.hasError(validation.name)">{{ validation.message$ | async }}</mat-error>
    </ng-container>
    <ng-container ngProjectAs="mat-error">
      <mat-error *ngIf="group.hasError('custom')">{{ group.getError('custom').message }}</mat-error>
    </ng-container>
  </mat-form-field>
</div>
