<section class="l-siteContent" id="theTop">
  <section class="checkout">
    <div class="row">
      <div class="small-12 column">
        <header class="sectionHeader g-bottom">
          <h1><img src="/assets/images/heading_divider_left.svg" alt="decor">{{ 'webshop.Fizetés' | transloco }}<img
            src="/assets/images/heading_divider_right.svg" alt="decor"></h1>
        </header>
        <ul class="checkout-breadcrumb">
          <li>
            <span>{{ 'webshop.Adatok megadása' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Szállítási mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Fizetési mód' | transloco }}</span></li>
          <li>
            <span>{{ 'webshop.Összegzés' | transloco }}</span></li>
        </ul>

        <section class="g-top-thick">
          <div class="row align-spaced">
            <div class="small-12 column">
              <p class="g-row-thick text-center">{{ 'webshop.Fizetéshez be kell lépned.' | transloco }}<br> {{ 'webshop.Ha még nem regisztráltál adj meg egy e-mail címet és egy jelszót.' | transloco }}</p>
            </div>
            <div class="small-12 medium-10 large-5 column">
              <div class="g-row">
                <h2>{{ 'webshop.Regisztráció' | transloco }}</h2>
                <form class="form form--signUp" data-abide novalidate [formGroup]="registrationGroup">
                  <div data-abide-error class="alert callout h-hidden">
                    <p>{{ 'webshop.Hibás kitöltés!' | transloco}}</p>
                  </div>

                  <label>{{ 'webshop.Vezetéknév' | transloco}}
                    <input type="text" [formControl]="registrationLastName" >
                    <span class="form-error" *ngIf="registrationLastName.hasError('required') && registrationLastName.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                  </label>
                  <label>{{ 'webshop.Keresztnév' | transloco}}
                    <input type="text" [formControl]="registrationFirstName" >
                    <span class="form-error" *ngIf="registrationFirstName.hasError('required') && registrationFirstName.touched">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                  </label>

                  <label>{{ 'webshop.E-mail' | transloco}}
                    <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControl]="registrationEmail">
                    <span class="form-error"
                          *ngIf="registrationEmail.hasError('email')">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco}}</span>
                    <span class="form-error"
                          *ngIf="registrationEmail.hasError('required')">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                    <span class="form-error" *ngIf="registrationEmail.hasError('owned') && registrationEmail.touched">{{ 'webshop.Ezt az email cím már foglalt, kérjük jelentkezz be' | transloco}}</span>
                  </label>
                  <label>{{ 'webshop.Telefon' | transloco}}
                    <input type="tel" pattern="[0-9]*" [placeholder]="'webshop.Telefon' | transloco" [formControl]="registrationPhone">
                    <span class="form-error"
                          *ngIf="registrationPhone.hasError('required')">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                  </label>
                  <label>{{ 'webshop.Jelszó' | transloco}}
                    <input type="password" [placeholder]="'webshop.Jelszó' | transloco" [formControl]="registrationPassword">
                    <span class="form-error"
                          *ngIf="registrationPasswordAgain.hasError('required')">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                  </label>
                  <label>{{ 'webshop.Jelszó megerősítése' | transloco}}
                    <input type="password" [placeholder]="'webshop.Jelszó megerősítése' | transloco" [formControl]="registrationPasswordAgain">
                    <span class="form-error"
                          *ngIf="registrationPasswordAgain.hasError('required')">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco}}</span>
                  </label>
                  <div class="row">
                    <div class="small-12 medium-6 column">
                      <div class="g-top-thin">
                        <input id="needNewsletter" type="checkbox"><label
                        for="needNewsletter">{{ 'webshop.Hírlevelet kérek' | transloco}}</label>
                      </div>
                    </div>
                    <div class="small-12 medium-6 column">
                      <div class="text-right">
                        <button class="btn btn--basic" type="submit"
                                (click)="registration()">{{ 'webshop.Fiók létrehozása' | transloco}}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="small-12 medium-10 large-5 column">
              <div class="g-row">
                <h2>{{ 'webshop.Belépés' | transloco }}</h2>
                <form class="form form--signIn" data-abide novalidate [formGroup]="loginGroup">
                  <img class="anim anim--fromRight decorImg decorImg--signInCheckout" src="/assets/images/decor/parrot.png"
                       alt="">
                  <div data-abide-error class="alert callout h-hidden">
                    <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
                  </div>
                  <label>{{ 'webshop.E-mail' | transloco }}
                    <input type="text" [placeholder]="'webshop.E-mail' | transloco" [formControl]="loginEmail">
                    <span class="form-error"
                          *ngIf="loginEmail.hasError('email')">{{ 'webshop.Ez a mező nem felel meg az e-mail formátumnak.' | transloco }}</span>
                  </label>
                  <label>{{ 'webshop.Jelszó' | transloco }}
                    <input type="password" [placeholder]="'webshop.Jelszó' | transloco" [formControl]="loginPassword">
                    <span class="form-error"
                          *ngIf="loginEmail.hasError('required')">{{ 'webshop.Ezt a mezőt kötelező kitölteni' | transloco }}</span>
                  </label>
                  <div class="row align-middle">
                    <div class="column">
                      <a class="btn btn--normalText"
                         [routerLink]="['/forgotten-password'] | localize">{{ 'webshop.Elfelejtettem a jelszavam' | transloco }}</a>
                    </div>
                    <div class="shrink column">
                      <div class="text-right">
                        <button class="btn btn--basic" (click)="login()">{{ 'webshop.Belépés' | transloco }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  </section>
</section>
