<div class="mat-elevation-z8">
  <mat-toolbar>
    <mat-toolbar-row>
      {{ getService().getEntityTitle() | async }} {{ 'form.listing' | transloco }}
    </mat-toolbar-row>
    <mat-toolbar-row>
      <button style="margin-right: 15px" mat-raised-button color="primary"
              routerLink="./create">{{ 'form.new' | transloco }}</button>
      <button mat-raised-button color="warn" (click)="batchDelete()">{{ 'form.batchDelete' | transloco }}</button>
    </mat-toolbar-row>
  </mat-toolbar>
  <table mat-table class="full-width-table" matSort>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" [attr.data-label]="''">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{ column }}">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header>{{ column !== 'id' ? (translationDomain + '.' + column | transloco) : ('id' | transloco) }}</th>
      <td mat-cell *matCellDef="let row" [attr.data-label]="column !== 'id' ? (translationDomain + '.' + column | transloco) : ('id' | transloco)">
        <ng-container *ngIf="fieldConfig(column)?.type === 'currency'">
          {{ row[column] | currency: (fieldConfig(column)?.customContent(null, of(row)) | async) : 'symbol' : '0.0-2'  }}
        </ng-container>
        <ng-container *ngIf="fieldConfig(column)?.type === 'dateRange' && fieldConfig(column)?.inputType !== 'dateDay'">
          {{ row[column] | dateTime }}
        </ng-container>
        <ng-container *ngIf="fieldConfig(column)?.type === 'dateRange' && fieldConfig(column)?.inputType === 'dateDay'">
          {{ row[column] | dateDay }}
        </ng-container>
        <ng-container *ngIf="fieldConfig(column)?.type === 'select'">
          {{ fieldConfig(column)?.getOptionName(row[column]) | async }}
        </ng-container>
        <ng-container *ngIf="fieldConfig(column)?.type !== 'dateRange' && fieldConfig(column)?.type !== 'select' && fieldConfig(column)?.type !== 'currency'">
          {{ row[column] }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="filter_select">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>

    <ng-container *ngFor="let column of displayedColumns" matColumnDef="filter_{{ column }}">
      <th mat-header-cell *matHeaderCellDef>
        <p *ngIf="fieldConfig(column)?.visible && fieldConfig(column)?.filterable" dynamicField [field]="fieldConfig(column)" [group]="filterForm"></p>
      </th>
    </ng-container>


    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | transloco }}</th>
      <td mat-cell *matCellDef="let row">
        <button *ngIf="canView()" mat-mini-fab color="primary" [routerLink]="['./view', row.id]">
          <mat-icon>view_list</mat-icon>
        </button>
        <a *ngIf="canEdit()" mat-mini-fab color="primary" [routerLink]="['./', row.id]">
          <mat-icon>edit</mat-icon>
        </a>
        <button *ngIf="canEdit()" mat-mini-fab color="warn" (click)="delete(row.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="filter_actions">
      <th mat-header-cell *matHeaderCellDef>
        <button (click)="resetFilter()" mat-raised-button color="primary">{{ 'reset' | transloco }}</button>
      </th>
    </ng-container>

    <tr class="filter-row" mat-header-row *matHeaderRowDef="getFilterColumns()"></tr>
    <tr mat-header-row *matHeaderRowDef="getAllColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getAllColumns();"
        (click)="selection.toggle(row)">
    </tr>
  </table>

  <mat-paginator
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]"
  >
  </mat-paginator>
</div>
