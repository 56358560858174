import {Injectable} from '@angular/core';
import {OrderState, Store as OrderStore} from './store';
import {EntityService} from '../../../components/entity-service';
import {Order, OrderItem, OrderLog} from './model';
import {FieldConfig} from '../../../components/form/field.interface';
import {FormGroup, Validators} from '@angular/forms';
import {isString, TranslocoService} from '@ngneat/transloco';
import {first, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {OrderQuery} from './query';
import {UserService} from "../../../authentication/user/state/service";
import {TransporterService} from "../../transporter/state/service";
import {Observable, of} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {User} from "../../../authentication/user/state/model";
import * as moment from "moment";
import {Entity} from "../../../components/entity";
import {PaymentModeService} from "../../payment-mode/state/service";

@Injectable({providedIn: 'root'})
export class OrderService extends EntityService<OrderStore, OrderState> {

  orders$ = this.get().pipe(
    switchMap(() => this.query.selectAll()),
    map(items => items.map(item => new Order(item))),
    shareReplay(1)
  );
  editForm = {
    cancelTitle: this.translocoService.selectTranslate('form.cancel'),
    submitTitle: this.translocoService.selectTranslate('form.submit'),
    entityTitle: this.translocoService.selectTranslate('order.entityTitle'),
    fields: [
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('order.orderId'),
        inputType: 'number',
        name: 'orderId',
        listed: true,
        disabled: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('order.innerDescription'),
        name: 'innerDescription',
        listed: false,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: of('Megjegyzés'),
        name: 'log',
        listed: true,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('order.innerDescription'),
        name: 'currency',
        listed: false,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('order.innerDescription'),
        name: 'innerDescription',
        listed: false,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('order.innerDescription'),
        name: 'trackingCode',
        listed: false,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'date',
        label: this.translocoService.selectTranslate('order.createdAt'),
        name: 'createdAt',
        listed: true,
        editable: false,
        validations: []
      }),
      FieldConfig.create({
        type: 'currency',
        label: this.translocoService.selectTranslate('order.sumDiscountedPrice'),
        inputType: 'number',
        name: 'sumDiscountedPrice',
        customContent: (formGroup?: FormGroup, entity$?: Observable<Order>) => entity$.pipe(map(entity => entity.currency)),
        listed: true,
        disabled: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'autocomplete',
        entityConditionalOptions: (formControl) => {
          return this.userService.filter$(formControl?.value as string);
        },
        listed: true,
        editable: false,
        label: this.translocoService.selectTranslate('order.user'),
        name: 'user',
        validations: []
      }),

      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.transporterService.transporters$,
        listed: true,
        editable: false,
        label: this.translocoService.selectTranslate('order.shippingMode'),
        name: 'shippingMode',
        validations: []
      }),
      FieldConfig.create({
        type: 'entitySelect',
        entityOptions: this.paymentModeService.paymentModes$,
        label: this.translocoService.selectTranslate('cart.paymentMode'),
        name: 'paymentMode',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'items',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'billingAddress',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'billingCountry',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'input',
        label: this.translocoService.selectTranslate('cart.slug'),
        inputType: 'text',
        name: 'shippingAddress',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.quantitativeUnit'),
        inputType: 'text',
        name: 'shippingCountry',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'select',
        label: of('Gyártandó?'),
        name: 'manufacturable',
        listed: true,
        editable: false,
        options: [
          {
            name$: of('Igen'),
            value: "1"
          },
          {
            name$: of('Nem'),
            value: "0"
          }
        ],
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'entitySelect',
        label: this.translocoService.selectTranslate('cart.quantitativeUnit'),
        inputType: 'text',
        name: 'store',
        listed: false,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
      FieldConfig.create({
        type: 'select',
        label: this.translocoService.selectTranslate('order.status'),
        options: [
          {
            name$: this.translocoService.selectTranslate('order.status-item.0'),
            value: 0
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.1'),
            value: 1
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.2'),
            value: 2
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.3'),
            value: 3
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.4'),
            value: 4
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.5'),
            value: 5
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.6'),
            value: 6
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.7'),
            value: 7
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.8'),
            value: 8
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.10'),
            value: 10
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.11'),
            value: 11
          },
          {
            name$: this.translocoService.selectTranslate('order.status-item.99'),
            value: 99
          }
        ],
        name: 'status',
        listed: true,
        validations: [
          {
            name: 'required',
            validator: Validators.required,
            message$: this.translocoService.selectTranslate('form.required')
          }
        ]
      }),
    ]
  };

  constructor(
    protected translocoService: TranslocoService,
    private query: OrderQuery,
    private userService: UserService,
    private transporterService: TransporterService,
    private paymentModeService: PaymentModeService,
    protected store: OrderStore) {
    super(store, Order);
  }


  simplifyEntity(entity: Entity) {
    if (!entity || isString(entity)) {
      return entity;
    }

    if (entity.hasOwnProperty('coupon')) {
      // @ts-ignore
      if (!entity.coupon.id) {
        delete entity['coupon'];
      }
    }
    if (entity.hasOwnProperty('id') && !(entity instanceof OrderItem) && entity.id) {
      entity = {id: entity.id} as unknown as Entity;
    } else {
      if (entity.hasOwnProperty('id') && !entity.id) {
        delete entity['id'];
      }
      const names = Object.getOwnPropertyNames(entity);
      for (const _entityKey in names) {
        if (Array.isArray(entity[_entityKey])) {
          for (const __entityKey in (entity[_entityKey] as Entity[])) {
            entity[_entityKey][__entityKey] = this.simplifyEntity(entity[_entityKey][__entityKey]);
          }
        } else {
          entity[names[_entityKey]] = this.simplifyEntity(entity[names[_entityKey]] as Entity);
        }
      }
    }

    return entity;
  }


  export$(params: HttpParams | { [p: string]: string | string[] } | undefined): Observable<any> {
    return this.getHttp().get(
      this.api + '/export',
      {
        responseType: 'blob',
        params
      },
    ).pipe(
      first(),
      tap((resp) => {
        this.downloadXLSFile(resp as BlobPart, 'rendeles_export_' + moment(params['from']).format('YYYY-MM-DD') + '-' + moment(params['to']).subtract(1, 'day').format('YYYY-MM-DD'));
      }),
      first(),
      take(1),
      shareReplay(1)
    );
  }

  exportManufacturable$(params: HttpParams | { [p: string]: string | string[] } | undefined): Observable<any> {
    return this.getHttp().get(
      this.api + '/manufacturable-export',
      {
        responseType: 'blob',
        params
      },
    ).pipe(
      first(),
      tap((resp) => {
        this.downloadXLSFile(resp as BlobPart, 'gyartando_export_' + moment(params['from']).format('YYYY-MM-DD') + '-' + moment(params['to']).subtract(1, 'day').format('YYYY-MM-DD'));
      }),
      first(),
      take(1),
      shareReplay(1)
    );
  }

  getByUser(user: User): Observable<Order[]> {
    return this.getHttp().get<Order[]>(this.api + '/detail?s=-created_at&c=10&q[]=user:eq:' + user.id)
      .pipe(
        map(items => {
          return items.map(_item =>  new this._constructor(_item) as Order);
        }),
        take(1),
        shareReplay(1),
        tap(entities => {
          this.store.upsertMany(entities);
        }),
      );
  }

  getLogsByOrder(order: Order): Observable<OrderLog[]> {
    return this.getHttp().get<Order[]>(this.api + '/' + order.id + '/logs')
      .pipe(
        map(items => {
          return items.map(_item =>  new OrderLog(_item));
        }),
        take(1),
        shareReplay(1),
      );
  }


  addLog$(order: Order, log: string) {
    if (!log) {
      return of('');
    }
    return this.getHttp().post<any>(this.api + '/' + order.id + '/logs', {
      log: log
    });
  }

  get resourceName(): string {
    return 'webshop/order';
  }

}
