<div data-abide-error class="alert callout h-hidden">
  <p>{{ 'webshop.Hibás kitöltés!' | transloco }}</p>
</div>
<div class="row">
  <div class="small-12 medium-6 column">
    <input autocomplete="new-password" type="radio" id="private" [formControl]="isCompanyControl" [value]="false"
           class="form--address-actLike-private">
    <label for="private">{{ 'webshop.Magánszemély' | transloco }}</label>
  </div>
  <div class="small-12 medium-6 column">
    <input autocomplete="new-password" type="radio" id="company" [formControl]="isCompanyControl" [value]="true"
           class="form--address-actLike-company">
    <label for="company">{{ 'webshop.Céges rendelés' | transloco }}</label>
  </div>
</div>
<div class="row form--address-actLike-company-block" *ngIf="!isCompanyControl.value">
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Vezetéknév' | transloco }}
      <input autocomplete="new-password" type="text" [placeholder]="'webshop.Vezetéknév' | transloco" [formControl]="lastNameControl">
      <span *ngIf="lastNameControl.hasError('required') && lastNameControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>


    </label>
  </div>
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Keresztnév' | transloco }}
      <input autocomplete="new-password" type="text" [placeholder]="'webshop.Keresztnév' | transloco" [formControl]="firstNameControl">
      <span *ngIf="firstNameControl.hasError('required') && firstNameControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
</div>
<div class="row" *ngIf="isCompanyControl.value">
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Cégnév' | transloco }}
      <input autocomplete="new-password" type="text" [placeholder]="'webshop.Cégnév' | transloco" [formControl]="companyNameControl">
      <span *ngIf="companyNameControl.hasError('required') && companyNameControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Adószám' | transloco }}
      <input autocomplete="new-password" type="text" [placeholder]="'webshop.Adószám' | transloco" [formControl]="vatNumberControl">
      <span *ngIf="vatNumberControl.hasError('required') && vatNumberControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
      <span *ngIf="vatNumberControl.hasError('vat') && vatNumberControl.touched" class="form-error is-visible shown">{{ 'webshop.Hibás formátum' | transloco }}</span>
    </label>
  </div>
</div>
<div class="row">
  <div class="small-12 medium-8 column">
    <label>{{ 'webshop.Ország' | transloco }}
      <select [formControl]="countryControl" [compareWith]="formService.compareById">
        <option *ngFor="let country of (countries$ | async)  | orderBy: 'name'" [ngValue]="country">
          {{ country.name }}
        </option>
      </select>
      <span *ngIf="countryControl.hasError('required') && countryControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
  <div class=" small-12 medium-4 column">
    <label>{{ 'webshop.Irányítószám' | transloco }}
      <input autocomplete="new-password" [formControl]="postalCodeControl" type="text"
             [placeholder]="'webshop.Irányítószám' | transloco">
      <span *ngIf="postalCodeControl.hasError('required') && postalCodeControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
</div>
<div class="row">
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Állam/Megye' | transloco }}
      <input autocomplete="new-password" [formControl]="countyControl" type="text"
             [placeholder]="'webshop.Állam/Megye' | transloco">
      <span *ngIf="countyControl.hasError('required') && countyControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
  <div class="small-12 medium-6 column">
    <label>{{ 'webshop.Város' | transloco }}
      <input autocomplete="new-password" [formControl]="cityControl" type="text"
             [placeholder]="'webshop.Város' | transloco">
      <span  *ngIf="cityControl.hasError('required') && cityControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
</div>
<div class="row">
  <div class="small-12 medium-12 column">
    <label>{{ 'webshop.Utca/házszám' | transloco }}
      <input autocomplete="new-password" [formControl]="streetControl" type="text"
             [placeholder]="'webshop.Utca' | transloco">
      <span *ngIf="streetControl.hasError('required') && streetControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
</div>

<div class="row">
  <div class="small-12 medium-12 column">
    <label>{{ 'webshop.Telefonszám' | transloco }}
      <span class="is-visible shown"
            style="font-size: 80%">{{ 'webshop.Kérjük a telefonszámot országhívóval együtt adja meg!' | transloco }}</span>
      <p>
        <span style="display: inline-block">+</span>
        <input style="width: calc(100% - 1em); display: inline-block;" autocomplete="phonenumber"
               [formControl]="phoneControl" type="tel" pattern="[0-9]*" [placeholder]="'webshop.Telefonszám' | transloco">
      </p>
      <span *ngIf="phoneControl.hasError('required') && phoneControl.touched" class="form-error is-visible shown">{{ 'webshop.Ezt a mezőt ki kell tölteni' | transloco }}</span>
    </label>
  </div>
</div>
<div class="row" *ngIf="this.addToAnotherType">
  <div class="small-12 medium-12 column">
    <input id="addAnotherType" [formControl]="addAnotherTypeControl" type="checkbox"><label
    for="addAnotherType">{{ 'webshop.Mentés számlázási címként is' | transloco }}</label>
  </div>
</div>
